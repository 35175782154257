@import "colors.scss";

.main-hero {
    position: relative;
    overflow: visible;
    .hero-body {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        overflow: visible;
        font-family: "Montserrat", sans-serif;

        .content {
            h1,
            p {
                max-width: 31.25rem;
            }

            .title {
                color: $color-red;
                font-size: 2.5rem;
                line-height: 2.8125rem;
            }
            p {
                color: #555;
                //color: #fff;
            }
        }

        &:before {
            content: "";
            display: block;
            width: 150%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            // background: radial-gradient(circle,hsla(0,0%,100%,.25) 0,hsla(0,0%,100%,0) 100%);
            background: $radial-gradient-white;
            transform: translateX(-40%);
        }
    }
}

.search-form {
    max-width: 43.75rem;
    .input {
        border: none;
        border-radius: 0.875rem;
        border: 0.1875rem solid $color-red-darker;
        padding-left: 2.1875rem !important;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    ::placeholder {
        color: #666;
    }

    hr {
        margin: 0.625rem 0;
    }

    .field {
        .control {
            width: 100%;
            font-size: 1.15rem;
        }
        .icon {
            margin-top: 0.25rem;
        }
    }

    .select-container {
        .vs__dropdown-toggle {
            width: 100%;
            border: 0.1875rem solid $color-red-darker;
            border-left: none;
            height: 3.375rem;
            font-size: 1.25rem;
            background: #fff;
            border-radius: 0;
            border-top-right-radius: 0.875rem;
            border-bottom-right-radius: 0.875rem;
        }

        #vs1__listbox {
            margin-left: 0;
            margin-top: 0;
        }

        .vs__search {
            font-size: 1.15rem;
        }
    }

    position: relative;

    .search-dropdown {
        background-color: #fff;
        padding: 1.25rem;
        border-radius: 0.3125rem;
        z-index: 3;
        box-shadow: 0 0 0.9375rem rgba(0, 0, 0, 0.1);

        .search-items {
            margin-bottom: 0.625rem;

            &:last-child {
                margin-bottom: 0;
            }

            .section-title {
                font-weight: bolder;
                color: $color-gray-darker;
                margin-bottom: 0.625rem;
            }

            .links {
                display: flex;
                flex-wrap: wrap;

                .tag {
                    margin-right: 0.3125rem;
                    margin-bottom: 0.3125rem;
                }
            }
        }

        position: absolute;
        left: 0;
        top: 3.75rem;
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
}

.lds-ellipsis div {
    position: absolute;
    top: 2.0625rem;
    width: 0.8125rem;
    height: 0.8125rem;
    border-radius: 50%;
    background: $color-red;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 0.5rem;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 0.5rem;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 2rem;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 3.5rem;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(1.5rem, 0);
    }
}

@media (max-width: 47.9375rem) {
    .search-form {
        .field {
            display: block;

            .input,
            .select {
                width: 100%;
                border-radius: 0.875rem !important;
                border: 0.1875rem solid $color-red-darker;
            }
            .input {
                margin-bottom: 0.625rem;
            }
        }

        .search-dropdown {
            margin-top: 3.125rem;
        }

        .select-container {
            .vs__search {
                font-size: 1rem;
            }
            .vs__dropdown-toggle {
                height: 2.75rem;
                border-radius: 0.875rem;
                border-left: 0.1875rem solid $color-red-darker;
                max-width: 12.5rem;
            }
        }
    }
}

@media screen and (max-width: 47.9375rem) {
    .main-hero {
        .hero-body {
            background-image: var(--hero-background-mobile) !important;
            min-height: 22rem;
            display: flex;
            flex-direction: column;
            align-items: end;
            padding: 1.25rem 0;
            .content {
                text-align: left;
                .title {
                    max-width: 100%;
                    font-size: 1.6rem !important;
                    line-height: 1.7rem !important;
                }
                p {
                    display: none;
                }
                .field {
                    justify-content: center;
                }
            }
        }
        .container {
            display: flex;
            align-items: end;
            height: 100%;
        }
        .search-form {
            .input {
                font-size: 1rem;
            }
            .control {
                margin-left: 0;
                margin-right: auto;
            }
        }
    }

    .section-title {
        h1 {
            font-size: 1.6rem;
        }
    }
    .featured-container {
        .counters {
            margin-top: 0;
            .counter {
                .value {
                    font-size: 4.5rem;
                    height: 4.8rem;
                }
                .text {
                    font-size: 1rem;
                }
            }
        }

        .image {
            display: none;
            position: relative;
            max-width: 18.75rem;
            top: 0;
        }
    }

    .featured-items .columns {
        display: flex;
        width: 100%;
        .column {
            width: 50%;
        }
    }

    section.cta {
        .image,
        .image-container {
            display: none;
        }
        .content .text {
            font-size: 1rem;
        }
        &:before {
            background: rgba(255, 255, 255, 0.7) !important;
        }
    }

    section.stories-container .stories {
        margin-left: -0.625rem;
        margin-right: -0.625rem;
    }

    .team-container {
        padding-top: 3.125rem;
        .team {
            .team-item {
                .team-item-inner {
                    min-height: 18rem;
                    height: 18rem;
                }
            }
        }
    }

    section.services-container {
        padding-bottom: 0;
        .services {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    section.about {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        background-position: bottom center;
        background-attachment: scroll;
        min-height: 0;
        .content h1 {
            font-size: 1.8rem;
        }
    }

    .services-container .service .content h1 {
        font-size: 1rem;
    }

    // .pricings {

    //     .column {
    //         max-width: 100%;
    //         width: 100%;
    //     }
    //     .pricing {
    //         max-width: 100%;
    //     }
    //     display: flex !important;
    //     flex-direction: column;

    //     .column:nth-child(1) {
    //         order: 2;
    //     }
    //     .column:nth-child(2) {
    //         order: 1;
    //     }
    //     .column:nth-child(3) {
    //         order: 3;
    //     }
    // }

    section.about {
        padding-top: 10rem;
    }
}

@media (min-width: 767px) {
    .main-hero .hero-body #busca{
        width: 37.75rem!important;
        right: 21rem!important;
    }
}

