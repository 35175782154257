$color-red: #FF0016;
$color-red-darker: #C8010F;
$color-red-dark: #830006;
$color-infoblue-lighter: #3498db;
$color-blue: #2980b9;
$color-orange: #f39c12;
$color-success: #2ecc71;

$radial-gradient-white: radial-gradient(circle, rgba(255,255,255,.9) 20%, rgba(255,255,255,0) 70%);

$gradient-red: linear-gradient(176deg, rgba(131,8,13,1) 0%, rgba(230,0,19,1) 100%);
$gradient-white: linear-gradient(176deg, rgba(255,255,255,0) 20%, rgba(255,255,255,1) 70%);

// $gradient-gold: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
// radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);

$gradient-gold: linear-gradient( 45deg, #c39738, #deb761, #c39738);

$gradient-silver: linear-gradient( 45deg, #bcc6cc, #eee, #bcc6cc);

$color-gray-darker: #999;

$gradient-eol-rtl: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 70%);

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
